import React, {useState} from 'react'
/* eslint-disable */
import classes from '../styles/admin-page.module.sass' // is used
/* eslint-enable */

import { useMsal } from '../utility/auth/msalContext';

const AdminPage = () => {

    const [usersData, setUserData] = useState()
    const [progData, setProgData] = useState()
    const [user, setUser] = useState()
    
    const {getUsersFromApi, getUserResultsFromApi} = useMsal();

    // https://stackoverflow.com/questions/34156282/how-do-i-save-json-to-local-text-file
    function downloadToFile(content, fileName, contentType) {
        var a = document.createElement("a");
        var file = new Blob([content], {type: contentType});
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }
   
    function saveFile() {
        downloadToFile(progData, 'json.txt', 'text/plain');
    }

    async function download(e) { 
        e.preventDefault();    

        setUserData((JSON.stringify(await getUsersFromApi()).replace(/,/g, '\n') ))
        
        const data = JSON.stringify(await getUserResultsFromApi(user))
        setProgData(data)
    }

    function handleChange(event) {    
        setUser(event.target.value);
      }


    return (
        <>
            <div>
                <h2>Registered users:</h2>
                <textarea value={usersData}>         
                </textarea>
            </div>
            <div>
                <input type="text" placeholder="xxx123@liu.se" value={user} onChange={handleChange}></input>
                <button onClick={download}>  
                    Fetch userdata
                </button>

                <button onClick={saveFile}>  
                    Save userdata
                </button>

                <br></br>
                <h2>Userdata:</h2>
                <textarea value={progData}>
            
                </textarea>
            </div>
        </>
    )
}

export default AdminPage 